import {
    Box,
    Text,
    Heading
  } from '@chakra-ui/react';
  import { IoCallOutline, IoLogoWhatsapp } from 'react-icons/io5';
  import { useState, useEffect } from 'react';
  import {
    useColorModeValue,
    useToast,
  } from '@chakra-ui/react';
  
  
  const HelpAndSupport = () => {
    const toast = useToast();
    const [screenConfigScreen, setScreenConfigScreen] = useState(null);
    const bg = useColorModeValue('white', 'gray.900');
    const color = useColorModeValue('black', 'white');
    const [mountScreenConfig, setMountScreenConfig] = useState(true);
  
  
  
    useEffect(() => {
      if (mountScreenConfig) {
        setMountScreenConfig(false);
      }
    });
  
  
    const handlePress = async (title, phoneNumber) => {
      if (title === 'Call Us') {
        navigator.clipboard.writeText(phoneNumber);
        toast({
          title: 'Phone number copied to clipboard',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        const url = `tel:${phoneNumber}`;
        try {
          window.open(url, '_blank');
        } catch (error) {
          toast({
            title: 'Unable to make call. Please check phone settings.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } else if (title === 'Whatsapp us') {
        const url = `https://wa.me/${phoneNumber}?text=Hello, I need support regarding.`;
        try {
          window.open(url, '_blank');
        } catch (error) {
          toast({
            title: 'Unable to open WhatsApp. Please make sure WhatsApp is installed and try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };
  
  
    return (
      <Box bg={bg} color={color} py={10} px={20}>
        <Heading fontSize={'4xl'} fontWeight={'bold'} mb={5}>
          Help & Support
        </Heading>
        <Text fontSize={'xl'} color={'gray.700'} mb={10}>
          Don't hesitate to contact us whether you have a suggestion on our
          improvement, a complaint to discuss or an issue to solve.
        </Text>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} >
            <Box
                    bg={'gray.200'}
                    color={'black'}
                    px={{ base: 2, md: 10, lg:10, xl: 10}}
                    py={10}
                    borderRadius={'xl'}
                    width={{base: '40%' ,lg: '20%', xl: '20%'}}
                    height={'auto'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    onClick={() => handlePress('Call Us', screenConfigScreen?.phoneConfig?.phone_number)}
                    mr={{base:10, md: 15, lg: 25, xl: 35}}
            >
                <IoCallOutline size={{base: 5, md: 10, lg: 50,xl: 50}} mb={5} />
                <Text fontSize={{base: 'sm', md: 'md', lg: '2xl', xl: '2xl'}} fontWeight={'bold'} mb={2}>
                Call Us
                </Text>
                <Text  fontSize={{base: 'sm', md: 'sm', lg: 'md', xl: 'md'}} color={'gray.700'}>
                Our team is on the line Mon-Sun
                </Text>
            </Box>
            <Box
                bg={'gray.200'}
                color={'black'}
                px={{ base: 2, md: 10, lg:10, xl: 10}}
                py={10}
                borderRadius={'xl'}
                width={{base: '40%' ,lg: '20%', xl: '20%'}}
                height={'auto'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                onClick={() => handlePress('Whatsapp us', screenConfigScreen?.phoneConfig?.phone_number)}
                >
                <IoLogoWhatsapp size={{base: 10, md: 30, lg: 50,xl: 50}} mb={5} />
                <Text fontSize={{base: 'sm', md: 'md', lg: '2xl', xl: '2xl'}} fontWeight={'bold'} mb={2}>
                    Whatsapp us
                </Text>
                <Text fontSize={{base: 'sm', md: 'sm', lg: 'md', xl: 'md'}} color={'gray.700'}>
                    Our team is online Mon-Sun
                </Text>
            </Box>
        </Box>
      </Box>
    );
  };
  
  
  export default HelpAndSupport;