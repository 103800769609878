import {
    Box,
    Flex,
    Heading,
    Text,
    Link,
    VStack,
    Container,
    List,
    ListItem
  } from '@chakra-ui/react';
  
  const TermsAndCondition = () => {
    return (
      <Container maxW="container.xl" py={12}>
        <Box marginBottom={8}>
          <Heading as="h1" size="xl" mb={4} textAlign="center">
            Terms & Conditions
          </Heading>
          <Text fontSize="lg" mb={4} textAlign='left'>
            Thank you for choosing Wheelz365. We are delighted to welcome you to our community of car lovers.
            Spare a few minutes to read the following important TERMS & CONDITIONS regarding our services:
          </Text>
        </Box>
        <Flex height="100vh" overflow="hidden">
      <Box
        w="15%"
        minWidth="15%"
        marginRight={4}
        height="100vh"
        textAlign="left"
        overflow="auto"
        position="sticky"
        top={0}
        left={0}
        bg="white"
        zIndex={1}
        padding={4}
      >
        <VStack spacing={4} alignItems="flex-start">
          <Link href="#interior-cleaning">Interior cleaning</Link>
          <Link href="#exterior-cleaning">Exterior cleaning</Link>
          <Link href="#holidays">Holidays</Link>
          <Link href="#cleaner-assignment">Cleaner assignment</Link>
          <Link href="#start-and-renewal-data">Start and Renewal date</Link>
          <Link href="#renewals">Renewals</Link>
          <Link href="#safety-and-security">Safety and security</Link>
          <Link href="#liability">Liability</Link>
        </VStack>
      </Box>

      <Box
        flex={1}
        textAlign="left"
        width="85%"
        height="100vh"
        overflow="auto"
        padding={4}
      >
          <Section id="interior-cleaning" title="Interior Cleaning">
            <Text fontSize="lg" mb={4}>
            We will clean car interiors once a week. Our cleaning ninja will come and collect the keys from you or the society guard / receptionist and will return them to you once the cleaning has been completed. Our ninja will bring all the cleaning material and tools with them.
            </Text>
          </Section>
          <Section id="exterior-cleaning" title="Exterior cleaning">
            <Text fontSize="lg" mb={4}>
            We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
            </Text>
          </Section>

          <Section id="holidays" title="Holidays">
            <Text fontSize="lg" mb={4}>
            We do not provide any service on one day of the week. Any other holiday as the Govt guidelines will be observed and intimation will be sent to you in advance.
            </Text>
            <Text fontSize="lg" mb={4}>
            Our website uses these “cookies” to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.
            </Text>
          </Section>

          <Section id="cleaner-assignment" title="Cleaner assignment">
            <Text fontSize="lg" mb={4}>
            We hire and train our cleaning ninjas carefully to provide you the best service. A ninja will be assigned to you at the time of onboarding. This depends on the availability of the cleaners in your area. There will be situations where it will become impossible for the original cleaning ninja to continue (example: ninja leaving the service). In such cases, we will find you another ninja.
            </Text>
          </Section>

          <Section id="start-and-renewal-data" title="Start and Renewal date">
            <Text fontSize="lg" mb={4}>
            The start date of your subscription is the first day of cleaning. Renewal date will be the same date of every subsequent month.
            </Text>
          </Section>

        <Section id="renewals" title="Renewals">
            <Text fontSize="lg" mb={4}>
            To maintain active service please ensure that the payment is made before the renewal date. Your subscription will become inactive if the payment is not received before the renewal date. We strongly suggest that you set up auto debit
            </Text>
        </Section>

        <Section id="safety-and-security" title="Safety and security">
            <Text fontSize="lg" mb={4}>
                In order to protect our customers and cleaning ninjas, we recommend the following:
                <List>
                    <ListItem>
                        Please do not leave anything precious inside the car (including in the boot/trunk). Please make sure that there are no valuables before you hand over the keys to the cleaner.
                    </ListItem>
                    <ListItem>
                        Please check that the cleaner who comes to collect the keys is the one assigned to you. You can check his name, photo on the app.
                    </ListItem>
                    <ListItem>
                        Please report any issue immediately though the app, so that we can conduct a timely investigation.
                    </ListItem>
                </List>
            </Text>
        </Section>

          <Section id="liability" title="Liability">
            <Text fontSize="lg" mb={4}>
                We use minimal water cleaning, which leaves the car dust resistant. However, we will not be held responsible in case the car gets dirty immediately after cleaning due to rain, dust etc. It is advisable to park your car in the shade / basement. Avoid parking under the tree as bird droppings and leaves can damage the car paint.
            </Text>
          </Section>
      </Box>
    </Flex>

      </Container>
    );
  };
  
  const Section = ({ id, title, children }) => {
    return (
      <Box marginBottom={8}>
        <Heading as="h2" size="lg" mb={4} id={id}>
          {title}
        </Heading>
        {children}
      </Box>
    );
  };
  
  export default TermsAndCondition;