import React from 'react'
import { Route, Routes } from "react-router-dom";
import Landingpages from '../pages/landing.pages';
import Home from '../pages/home.pages';
import Blog from '../pages/blog.pages';
import HeaderComponent from '../component/header.component';
import FooterComponent from '../component/footer.component';
import ProductBookingPage from '../pages/product_booking.pages';
import CheckoutPage from '../pages/checkout.pages';
import PaymentConfirmation from '../pages/payment_confirmation.page';
import LoginPage from '../pages/login.page';
import PrivacyPolicy from '../pages/privacy_policy.pages';
import TermsAndCondition from '../pages/terms_and_condition.pages';
import HelpAndSupport from '../pages/help_and_support.page';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../reducers';
import Profile from '../pages/profile.page';
import AddressForm from '../component/addressform.component';


export default function Mainroutes() { 
  const isLoggedIn = useSelector(selectIsLoggedIn);


  return (
    <div>

      <HeaderComponent />
      <Routes>
        <Route path="/" element={<Landingpages />} />
        <Route path="/home/services" element={<Home />} />
        <Route path="/commingsoon" element={<Blog />} />
        <Route path="/product_booking" element={<ProductBookingPage/>}/>
        <Route path="/checkout" element={<CheckoutPage />}/>
        <Route path="/payment_confirmation" element={<PaymentConfirmation />}/>
        <Route path="/product_booking/car" element={<ProductBookingPage  service='car'  />}/>
        <Route path="/product_booking/bike" element={<ProductBookingPage   service='bike' />}/>
        <Route path="/help_support" element={<HelpAndSupport />}/>
        <Route path="/login" element={<LoginPage />}/>
        <Route path="/terms_and_conditions" element={<TermsAndCondition />}/>
        <Route path="/privacy_policy" element={<PrivacyPolicy />}/>
        <Route path="/profile" element={<Profile />}/>
        <Route path="/update_profile" element={<Profile />}/>
        <Route path="/address" element={<AddressForm />}/>
      </Routes>
      <FooterComponent />
    </div>
  )
}
